import { Alert } from '~/components/Alert'
import { ActionError } from '~/api/useActionError'

const strings = {
  unknownError: 'Unbekannter Fehler',
  errorCode: 'Fehlercode',
}

export const ActionErrorAlert = ({
  data,
  children,
}: {
  data: ActionError
  children?: React.ReactNode
  title?: string
}): React.ReactElement => {
  const code = data.code
  const message = data.message

  return (
    <Alert
      message={message ? message : strings.unknownError}
      // see comment in Alert.tsx on `autoFocus`
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={true}
    >
      {code && (
        <div className='mt-2 self-start'>
          <span className='text-body4 text-grey-800'>{strings.errorCode}:</span>{' '}
          <span className='text-caption2 text-grey-600'>{code}</span>
        </div>
      )}
      {children}
    </Alert>
  )
}
